import { Route } from "react-router-dom";
import { lazy } from "react";
import { URL_2024_FANDAY_IN_BKK_PROJECT } from "../constant/URL";

const DonationFanday2024Form = lazy(() =>
  import("../pages/project/01_project_fanday_2024/donation_form")
);
const DonationFanday2024FormResponse = lazy(() =>
  import("../pages/project/01_project_fanday_2024/donation_form_response")
);

export const ProjectRoutes = (
  <>
    <Route
      path={`project/${URL_2024_FANDAY_IN_BKK_PROJECT}/donation-form`}
      element={
        <DonationFanday2024Form endpoint={URL_2024_FANDAY_IN_BKK_PROJECT} />
      }
    />
    <Route
      path={`project/${URL_2024_FANDAY_IN_BKK_PROJECT}/donation-form-success`}
      element={<DonationFanday2024FormResponse isOK={true} />}
    />
    <Route
      path={`project/${URL_2024_FANDAY_IN_BKK_PROJECT}/donation-form-fail`}
      element={<DonationFanday2024FormResponse isOK={false} />}
    />
  </>
);
