import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import { ProjectRoutes } from "./routes_projects";

const Home = lazy(() => import("../pages/home"));
const Discography = lazy(() => import("../pages/discography"));
const Profile = lazy(() => import("../pages/profile"));
const Announcement = lazy(() => import("../pages/announce"));
const Media = lazy(() => import("../pages/media"));
const Interview = lazy(() => import("../pages/interview"));
const Schedule = lazy(() => import("../pages/schedule"));
const Project = lazy(() => import("../pages/project/00_project"));
// const Gallery = lazy(() => import("../pages/gallery"));

export const AllRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="profile/*" element={<Profile />} />
      <Route path="discography/*" element={<Discography />} />
      <Route path="schedule/*" element={<Schedule />} />
      <Route path="announcement/*" element={<Announcement />} />
      <Route path="media/*" element={<Media />} />
      <Route path="interview/*" element={<Interview />} />
      <Route path="project/*" element={<Project />} />
      {ProjectRoutes}
      {/* <Route path="gallery/*" element={<Gallery />} /> */}
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
};
